$hamburger-layer-color: #404040;
$hamburger-padding-x: 20px;
$hamburger-padding-y: 20px;

@import '~hamburgers/_sass/hamburgers/hamburgers.scss';

li {
    margin-left: 1rem;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-display: swap;
    font-family: 'Montserrat', sans-serif;
}
